/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import * as yup from 'yup'
import React, { useRef, useState } from 'react'
import { FieldArray, Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-hot-toast'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import GET_FUTURE_PRICINGS from '../../gql/queries/getFuturePricings'
import MODIFY_BASIS_VALUATIONS from '../../gql/mutations/modifyBasisValuations'
import GET_BASIS_VALUATIONS from '../../gql/queries/getBasisValuations'
import GET_PROFILE_SUMMARIES from '../../gql/queries/getProfileSummaries'
import SelectInput from '../inputs/SelectInput'
import SignedInput from '../SignedInput'
import { generateFutureMonths, renderQuantity, renderMoney, renderPrice } from '../../utils/helper'
import EmptyState from '../EmptyState'
import AlertBox from '../AlertBox'

const schema = yup.object().shape({
  basisRecordsArray: yup.array().of(
    yup.object().shape({
      futuresMonth: yup.string().required('Required'),
      basisAmount: yup.number().required('Required'),
      totalProduction: yup.number().required('Required')
    })
  )
})

function BasisValuationModal({
  isOpen,
  closeModal,
  profileSummary,
  clientId,
  basisMode,
  prodYear,
  data
}) {
  const { data: pricingsData } = useQuery(GET_FUTURE_PRICINGS)
  const [modifyBasisValuation] = useMutation(MODIFY_BASIS_VALUATIONS)
  const formRef = useRef()
  const cropName = `${profileSummary?.profileName} (${prodYear})`
  const { crop } = profileSummary
  const tradingMonths = generateFutureMonths(crop?.tradingMonths)
  const basisRecords = data?.getBasisValuations

  // eslint-disable-next-line consistent-return
  const onUpdate = async (values) => {
    // Check % Unsold
    const total = values.basisRecordsArray.reduce(
      (acc, obj) => acc + Number(obj.totalProduction),
      0
    )

    if (values.basisRecordsArray.length && total !== 100) {
      return setError(true)
    }

    toast
      .promise(
        modifyBasisValuation({
          variables: {
            input: {
              profileId: profileSummary.profileId,
              basisValuations: values.basisRecordsArray.map((obj) => ({
                ...obj,
                totalProduction: Number(obj.totalProduction)
              }))
            }
          },
          refetchQueries: [
            {
              query: GET_PROFILE_SUMMARIES,
              variables: {
                clientId,
                productionYear: Number(prodYear)
              }
            },
            {
              query: GET_BASIS_VALUATIONS,
              variables: { input: { profileId: profileSummary.profileId, prodYear } }
            }
          ]
        }),
        {
          loading: 'Adding basis valuation...',
          success: 'Basis valuation added successfully.',
          error: 'Error while adding the basis valuation.'
        }
      )
      .then(() => {
        closeModal()
      })
  }

  const basisRecordsInitialValues = {
    basisRecordsArray:
      !basisRecords || basisRecords.length === 0
        ? [
            {
              futuresMonth: tradingMonths && tradingMonths[1] ? tradingMonths[1].value : '',
              basisAmount: '',
              totalProduction: 100
            }
          ]
        : basisRecords?.map((record) => ({
            futuresMonth: record?.futuresMonth,
            basisAmount: record?.basisAmount,
            totalProduction: record?.totalProduction ? Number(record?.totalProduction) : 0
          }))
  }

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const calculateHighestAmount = (month) => {
    const monthYear = month ? month.split(' ') : []
    const futurePrice = pricingsData?.getFuturePricings
      ? (pricingsData?.getFuturePricings).filter(
          (item) =>
            item.month === String(monthYear[0]) &&
            item.year === Number(monthYear[1]) &&
            item?.crop?.id === profileSummary.crop.id
        )
      : []
    return futurePrice && futurePrice[0] && futurePrice[0].price ? futurePrice[0].price : '0'
  }

  const calculateQty = (totalProduction) => {
    const total = totalProduction ? Number(totalProduction) : 0
    const quantity = profileSummary?.unsold?.quantity ? Number(profileSummary?.unsold?.quantity) : 0
    const data = (total / 100) * quantity
    return Number(data)
  }

  const calculateTotal = (totalProduction, value) => {
    const total = totalProduction ? Number(totalProduction) : 0
    const amount = value ? Number(value) : 1
    const quantity =
      profileSummary?.unsold?.quantity && profileSummary.unsold.quantity > 0
        ? Number(profileSummary.unsold.quantity)
        : 0
    const data = (total / 100) * quantity

    return Number(data * amount)
  }

  const calculateTotalValue = (obj) => {
    const totalProduction = obj.totalProduction ? Number(obj.totalProduction) : 0
    const basisAmount = obj.basisAmount ? Number(obj.basisAmount) : 0
    const futuresAmount = calculateHighestAmount(obj?.futuresMonth)
    const amount = basisAmount + futuresAmount
    const quantity =
      profileSummary?.unsold?.quantity && profileSummary.unsold.quantity > 0
        ? Number(profileSummary.unsold.quantity)
        : 0
    const data = (totalProduction / 100) * quantity
    return Number(data * amount)
  }

  const addNewRow = (push, values) => {
    const totalProduction = values.reduce(
      (accumulator, obj) => accumulator + Number(obj.totalProduction),
      0
    )
    push({
      futuresMonth: tradingMonths && tradingMonths[1] ? tradingMonths[1].value : '',
      basisAmount: '',
      totalProduction: totalProduction < 100 ? 100 - totalProduction : 0
    })
  }

  const [error, setError] = useState(false)
  const inputRef = useRef(null)

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal} initialFocus={inputRef}>
      <div className="space-y-20">
        <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
          <Formik
            validationSchema={schema}
            onSubmit={onUpdate}
            innerRef={formRef}
            initialValues={basisRecordsInitialValues}
          >
            {({ values, setFieldValue, submitForm }) => {
              const totalPercentage = values.basisRecordsArray.reduce(
                (accumulator, obj) => accumulator + Number(obj.totalProduction),
                0
              )
              return (
                <FieldArray name="basisRecordsArray">
                  {({ insert, remove, push }) => (
                    <div className="px-4 sm:px-6 lg:px-8">
                      <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                          <h1 className="text-xl font-semibold text-gray-900">
                            Basis Valuation : {cropName}
                          </h1>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                          <button
                            onClick={() => addNewRow(push, values.basisRecordsArray)}
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                          >
                            Add basis
                          </button>
                        </div>
                      </div>
                      {error && (
                        <AlertBox message="The total of all % unsold should be equal to 100." />
                      )}
                      <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {values.basisRecordsArray?.length < 1 ? (
                              <div className="mx-32 w-96">
                                <EmptyState
                                  className="w-96 p-36"
                                  header="No valuations found"
                                  message="Please add a basis valuation"
                                  disabled
                                />
                              </div>
                            ) : (
                              <div className="pb-20  md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300 shadow ring-1 ring-black ring-opacity-5">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="max-w-200 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                                        style={{ width: 200 }}
                                      >
                                        Futures Month
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Price
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Basis
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Cash
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                                      >
                                        % Unsold
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                                      >
                                        Qty.
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                                      >
                                        Total
                                      </th>
                                      <th
                                        scope="col"
                                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                                      >
                                        <span className="sr-only">Delete</span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y bg-white">
                                    {values.basisRecordsArray?.map((record, index) => {
                                      const futuresAmount = calculateHighestAmount(
                                        record?.futuresMonth
                                      )
                                      return (
                                        <tr key={record?.id}>
                                          <td className="min-w-300 w-[00px] whitespace-nowrap pl-3">
                                            <SelectInput
                                              name={`basisRecordsArray.${index}.futuresMonth`}
                                              crop={crop}
                                              profile={profileSummary}
                                            />
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {renderPrice(futuresAmount)}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <SignedInput
                                              autoFocus
                                              innerRef={inputRef}
                                              type="number"
                                              name={`basisRecordsArray.${index}.basisAmount`}
                                              unit="$"
                                              containerStyle={{ height: '38px' }}
                                            />
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {renderPrice(
                                              futuresAmount +
                                                values.basisRecordsArray[index].basisAmount || 0
                                            )}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <SignedInput
                                              type="number"
                                              name={`basisRecordsArray.${index}.totalProduction`}
                                              unit="%"
                                              onChange={(obj) => {
                                                setFieldValue(
                                                  `basisRecordsArray.${index}.totalProduction`,
                                                  obj.target.value
                                                )
                                                setError(false)
                                              }}
                                              className="pr-7 text-right"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                            {renderQuantity(calculateQty(record.totalProduction))}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                            {renderMoney(
                                              calculateTotal(
                                                record.totalProduction,
                                                Number(futuresAmount) +
                                                  values.basisRecordsArray[index].basisAmount || 0
                                              )
                                            )}
                                          </td>
                                          {basisMode && (
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                                              <Button
                                                type="button"
                                                color="action"
                                                actionLabel="Delete"
                                                onClick={() => {
                                                  remove(index)
                                                  submitForm()
                                                }}
                                              />
                                            </td>
                                          )}
                                        </tr>
                                      )
                                    })}
                                    <tr>
                                      <th
                                        colSpan={5}
                                        scope="col"
                                        className={`px-3 py-3.5 text-right text-sm font-semibold text-gray-900 ${
                                          totalPercentage > 100 ? 'text-red-500' : ''
                                        }`}
                                      >
                                        {totalPercentage}
                                        {' %'}
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                                      >
                                        {renderQuantity(
                                          values.basisRecordsArray.reduce(
                                            (accumulator, obj) =>
                                              accumulator + calculateQty(obj.totalProduction),
                                            0
                                          )
                                        )}
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                                      >
                                        {renderMoney(
                                          values.basisRecordsArray.reduce(
                                            (accumulator, obj) =>
                                              accumulator + calculateTotalValue(obj),
                                            0
                                          )
                                        )}
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                                <div className="mt-6 flex justify-end gap-x-2">
                                  <Button
                                    type="button"
                                    color="neutral"
                                    label={basisMode ? 'Cancel' : 'Close'}
                                    onClick={closeModal}
                                  />
                                  {basisMode && (
                                    <Button
                                      type="submit"
                                      onClick={handleFormSubmit}
                                      color="info"
                                      label="Save"
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
              )
            }}
          </Formik>
        </div>
      </div>
    </BaseModal>
  )
}

export default BasisValuationModal
