import { gql } from '@apollo/client'

const MODIFY_FUTURE_PRICING = gql`
  mutation modifyFuturePricing($input: ModifyFuturePricingInput!) {
    modifyFuturePricing(input: $input) {
      id
      month
      year
      price
      commodityId
    }
  }
`

export default MODIFY_FUTURE_PRICING
