import { gql } from '@apollo/client'

const GET_FUTURE_PRICINGS = gql`
  query {
    getFuturePricings {
      id
      commodity {
        id
        name
        unit
        tradingMonths
        futuresName
      }
      crop {
        id
        name
        unit
      }
      month
      year
      price
    }
  }
`
export default GET_FUTURE_PRICINGS
