import React, { useState, useRef, useEffect } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import Table from '../components/tables/Table'
import TableDataCell from '../components/tables/TableDataCell'
import TableRow from '../components/tables/TableRow'

import { onwardsYears } from '../constants/years'
import SetFuturesPriceModal from '../components/modals/SetFuturesPriceModal'
import Button from '../components/buttons/Button'
import DELETE_FUTURE_PRICING from '../gql/mutations/deleteFuturePricing'
import DeleteModal from '../components/modals/DeleteModal'
import EmptyState from '../components/EmptyState'
import GET_CROPS from '../gql/queries/getCrops'
import GET_FUTURE_PRICINGS from '../gql/queries/getFuturePricings'
import Header from '../components/Header'
import Loader from '../components/Loader'
import { USER_ROUTES } from '../constants/routes'

export default function FuturesPricesPage() {
  const [isOpen, setIsOpen] = useState(false)
  const [mode, setMode] = useState(undefined)
  const [item, setItem] = useState(undefined)
  const [createUserModal, setCreateUserModal] = useState(undefined)

  const { data: cropsData, loading } = useQuery(GET_CROPS)
  const { data: pricingsData, loading: pricingLoading } = useQuery(GET_FUTURE_PRICINGS)
  const [prices, setPrices] = useState([])
  const cropRef = useRef()
  const yearRef = useRef()

  const itemsPerPage = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const currentItems = prices.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(prices.length / itemsPerPage)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % prices.length
    setItemOffset(newOffset)
  }

  useEffect(() => {
    if (pricingsData?.getFuturePricings) {
      updateFilter()
    }
  }, [pricingsData])

  const cropOptions = pricingsData?.getFuturePricings.reduce((group, arr) => {
    const { crop, commodity } = arr
    if (crop) group[crop.name] = true
    else group[commodity.name] = true
    return group
  }, {})

  const yearOptions = onwardsYears()

  const columns = ['Crop', 'Year', 'Month', 'Price', 'Actions']

  const navigate = useNavigate()
  const [deleteFuturePricing] = useMutation(DELETE_FUTURE_PRICING)
  const handleCloseModal = () => {
    setCreateUserModal(undefined)
  }

  const onDelete = () => {
    toast.promise(
      deleteFuturePricing({
        variables: {
          input: {
            id: item?.id
          }
        },
        refetchQueries: [GET_FUTURE_PRICINGS]
      }),
      {
        loading: 'Deleting the price...',
        success: 'Price deleted successfully.',
        error: 'Error while deleting the price.'
      }
    )
  }

  const updateFilter = () => {
    const crop = cropRef?.current?.value
    const year = yearRef?.current?.value
    const searchResults = pricingsData?.getFuturePricings.filter(
      (item) =>
        (!crop || item.crop.name === crop) && (!year || item.year.toString() === year.toString())
    )
    setItemOffset(0)
    setPrices(searchResults)
  }

  return (
    <>
      <Header>Futures Prices</Header>
      {(loading && !cropsData) || (pricingLoading && !pricingsData) ? (
        <Loader />
      ) : (
        <>
          <div className="flex flow-root sm:flex-row sm:items-center">
            <div className="relative float-left flex w-4/12 items-center">
              <Button
                label="Back to Admin"
                classes="mr-4 items-center"
                icon={<ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />}
                onClick={() => navigate(USER_ROUTES.ADMIN)}
              />
            </div>
            <div className="relative float-left flex w-4/12 items-center">
              <div className="mb-8 flex gap-x-2">
                <select
                  className="block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                  id="crop"
                  name="crop"
                  ref={cropRef}
                  onChange={updateFilter}
                >
                  <option value="">All crops</option>
                  {Object.keys(cropOptions)?.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
                <select
                  className="block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                  id="year"
                  name="year"
                  ref={yearRef}
                  onChange={updateFilter}
                >
                  <option value="">All years</option>
                  {yearOptions?.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="float-right mt-4 flex flex-row gap-2 sm:mt-0 sm:ml-16">
              <Button
                label="Set Price"
                onClick={() => {
                  setIsOpen(true)
                  setMode('ADD')
                  setItem({})
                }}
              />

              <Button
                label="Bulk Set Price"
                onClick={() => {
                  setIsOpen(true)
                  setMode('BULK_ADD')
                  setItem({})
                }}
              />
            </div>
          </div>
          {currentItems?.length < 1 ? (
            <EmptyState disabled header="No prices" message="Get started by adding a price." />
          ) : (
            <>
              <Table columns={columns}>
                {currentItems?.map((pricing) => (
                  <TableRow key={pricing?.id}>
                    <TableDataCell type="title">
                      {pricing?.crop?.name || pricing?.commodity?.name}
                    </TableDataCell>
                    <TableDataCell>{pricing?.year}</TableDataCell>
                    <TableDataCell>{pricing?.month}</TableDataCell>
                    <TableDataCell>{pricing?.price ? `$${pricing?.price}` : ''}</TableDataCell>
                    <TableDataCell>
                      <Button
                        type="button"
                        color="action"
                        actionLabel="Edit"
                        onClick={() => {
                          setIsOpen(true)
                          setMode('EDIT')
                          setItem(pricing)
                        }}
                      />
                      <Button
                        type="button"
                        color="action"
                        actionLabel="Delete"
                        onClick={() => {
                          setCreateUserModal('DELETE_FUTURE_PRICING')

                          setItem(pricing)
                        }}
                      />{' '}
                    </TableDataCell>
                  </TableRow>
                ))}
              </Table>

              <div className="mt-4 flex items-center justify-between border-gray-200 bg-white px-4 pt-3 sm:px-6">
                <p className="text-sm text-gray-700">
                  <span className="font-medium">{prices.length}</span>&nbsp;results
                </p>
                <div className="paginates relative">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    className="inline-flex"
                    previousClassName="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                    nextClassName="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                    pageLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-blue-200"
                    activeLinkClassName="relative z-10 inline-flex items-center border border-emerald-500 bg-emerald-50 px-4 py-2 text-sm font-medium text-emerald-600"
                    breakClassName="px-4"
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </>
          )}
          <SetFuturesPriceModal
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
            crops={cropsData?.getCrops || []}
            mode={mode}
            item={item}
          />
          {createUserModal === 'DELETE_FUTURE_PRICING' && (
            <DeleteModal
              title={`${item?.crop?.name} (${item?.month} ${item?.year})`}
              description="Are you sure you want to delete this price?"
              onSubmit={onDelete}
              onClose={handleCloseModal}
              isOpen={createUserModal === 'DELETE_FUTURE_PRICING'}
            />
          )}
        </>
      )}
    </>
  )
}
