/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import * as yup from 'yup'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { ErrorMessage, Form, Field, Formik, useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import AlertBox from '../AlertBox'
import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import BuyerModal from './BuyerModal'
import CREATE_DELIVERY from '../../gql/mutations/createDelivery'
import CREATE_SALE from '../../gql/mutations/createSale'
import CREATE_SALE_ADJUSTMENT from '../../gql/mutations/createSaleAdjustment'
import CustomDateInput from '../inputs/CustomDateInput'
import DELETE_DELIVERY from '../../gql/mutations/deleteDelivery'
import DELETE_SALE_ADJUSTMENT from '../../gql/mutations/deleteSaleAdjustment'
import EmptyState from '../EmptyState'
import GET_DELIVERIES from '../../gql/queries/getDeliveries'
import GET_FUTURE_PRICINGS from '../../gql/queries/getFuturePricings'
import GET_PROFILE_SUMMARIES from '../../gql/queries/getProfileSummaries'
import GET_SALE_ADJUSTMENTS from '../../gql/queries/getSaleAdjustments'
import GET_SALES_PROFILES from '../../gql/queries/getSalesProfile'
import Input from '../inputs/Input'
import Loader from '../Loader'
import { renderQuantity } from '../../utils/helper'
import roles from '../../constants/roles'
import saleTabs from '../../constants/saleTabs'
import SelectInput from '../inputs/SelectInput'
import SignedInput from '../SignedInput'
import UPDATE_SALE from '../../gql/mutations/updateSale'
import UPDATE_SALE_ADJUSTMENT from '../../gql/mutations/updateSaleAdjustment'
import { useAuth } from '../../hooks/useAuth'

const saleTypes = Object.freeze({
  CASH_SALE: 'Cash',
  BASIS: 'Basis',
  HTA: 'Futures Only - HTA',
  NPE: 'No Price Established- NPE'
})

const transportStatuses = Object.freeze({
  FOB: 'FOB',
  DELIVERED: 'Delivered'
})

const deliveryIntialValues = {
  deliveryAmount: undefined,
  date: undefined,
  notes: undefined
}

const saleAdjustmentInitialValues = {
  futuresMonth: '',
  basis: '',
  totalProduction: ''
}

const getDateString = (value) => {
  if (!value) return undefined
  if (!(value instanceof Date)) return undefined

  const year = value.getFullYear()
  const month = `${value.getMonth() + 1}`.padStart(2, '0')
  const date = value.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${date}`
}

function CreateSaleFormSubmitBtn({ label }) {
  const formik = useFormikContext()

  const submit = () => {
    console.log('form errors ', formik.errors)

    formik.submitForm()
  }

  return <Button type="button" color="info" label={label} onClick={submit} />
}

function SaleModal({
  buyers,
  closeModal,
  currentSaleValues,
  intialValues: salesIntialValues,
  profile,
  onCompleted,
  profiles,
  crops,
  isOpen,
  type
}) {
  const [basisMode, setBasisMode] = useState(!!salesIntialValues?.basisAmount)
  const { id, productionYearId } = useParams()
  const [isBuyerModalOpen, setIsBuyerModalOpen] = useState(false)
  const { data: profileSummaryData, loading: profileSummaryLoading } = useQuery(
    GET_PROFILE_SUMMARIES,
    {
      variables: {
        productionYear: Number(productionYearId),
        clientId: id
      }
    }
  )

  const auth = useAuth()

  const [createDelivery] = useMutation(CREATE_DELIVERY)
  const [createSale] = useMutation(CREATE_SALE)
  const [createSaleAdjustment] = useMutation(CREATE_SALE_ADJUSTMENT)
  const [deleteDelivery] = useMutation(DELETE_DELIVERY)
  const [deleteSaleAdjustment] = useMutation(DELETE_SALE_ADJUSTMENT)
  const [updateSale] = useMutation(UPDATE_SALE)
  const [updateSaleAdjustment] = useMutation(UPDATE_SALE_ADJUSTMENT)

  const {
    loading,
    data: deliveriesData,
    error
  } = useQuery(GET_DELIVERIES, {
    variables: {
      saleId: currentSaleValues?.saleId
    },
    skip: !currentSaleValues?.saleId
  })

  const {
    loading: loadingAdjustments,
    data: saleAdjustmentsData,
    error: errorAdjustments
  } = useQuery(GET_SALE_ADJUSTMENTS, {
    variables: {
      saleId: currentSaleValues?.saleId
    },
    skip: !currentSaleValues?.saleId
  })

  const { data: pricingsData } = useQuery(GET_FUTURE_PRICINGS)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [saleAdjustmentValues, setSaleAdjustmentValues] = useState(saleAdjustmentInitialValues)
  const [saleAdjustmentAction, setSaleAdjustmentAction] = useState('CREATE')
  const [crop, setCrop] = useState(
    crops?.find((crop) => crop?.id === salesIntialValues?.profile?.cropId) || null
  )
  const cropsProfileData = profiles?.filter((item) => item?.kind === 'CROP')

  const deliverySchema = yup.object({
    date: yup.date().required('Required'),
    deliveryAmount: yup
      .number()
      .required('Required')
      .test('max-decimal', 'Must have up to 4 decimal places', (value) => {
        if (value === undefined || value === null || value === '') {
          return true
        }
        return /^(\d{1,8}(\.\d{1,4})?)?$/.test(value.toString())
      })
  })

  const saleAdjustmentSchema = yup.object({
    futuresMonth: yup.string().required('Required'),
    basis: yup.number().test('max-decimal', 'Must have up to 4 decimal places', (value) => {
      if (value === undefined || value === null || value === '') {
        return true
      }
      return /^-?(\d{1,8}(\.\d{1,4})?)?$/.test(value.toString())
    })
  })

  const orderSchema = yup.object().shape({
    subkind: yup.string().ensure().required('Required'),
    profileId: yup.string().ensure().required('Required'),
    buyerId: yup.string().ensure().required('Required'),
    quantity: yup
      .string()
      .required('Required')
      .matches(/^\d+(\.\d{1,4})?$/, 'Must be a number with up to 4 decimal places'),
    deliveryStartDate: yup.date().required('Required'),
    deliveryEndDate: yup
      .date()
      .min(yup.ref('deliveryStartDate'), 'Delivery end date should be after delivery start date')
      .required('Required'),
    saleDate: yup.date().required('Required'),
    basisAmount: yup
      .number()
      .nullable()
      .test('required', 'Basis is required', (value, context) => {
        if (
          (context.parent.subkind === 'BASIS' ||
            (context.parent.subkind === 'CASH_SALE' && basisMode)) &&
          (value === null || value === undefined || value === '') // value = 0 is valid
        ) {
          return false
        }
        return true
      })
      .test(
        'max-decimal',
        'Must be a number having up to 4 decimal places',
        (value, context) =>
          context.parent.subkind !== 'BASIS' || /^-?(\d{1,8}(\.\d{1,4})?)?$/.test(value?.toString())
      ),
    feeAmount: yup
      .number()
      .nullable()
      .test(
        'max-decimal',
        'Must have up to 4 decimal places',
        (value) => !value || /^(\d{1,8}(\.\d{1,4})?)?$/.test(value?.toString())
      ),
    futuresAmount: yup
      .number()
      .typeError('Must be a number')
      .test('max-decimal', 'Must have up to 4 decimal places', (value, context) => {
        if (
          context.parent.subkind === 'HTA' &&
          (value === undefined || value === null || isNaN(value))
        ) {
          return true
        }
        return /^(\d{1,8}(\.\d{1,4})?)?$/.test(value?.toString() || '')
      })
      .nullable(),
    amount: yup
      .number()
      .typeError('Must be a number')
      .test('max-decimal', 'Must have up to 4 decimal places', (value) => {
        if (value === undefined || value === null || isNaN(value)) {
          return true
        }
        return /^(\d{1,8}(\.\d{1,4})?)?$/.test(value.toString())
      })
      .nullable(),
    futuresMonth: yup
      .string()
      .nullable()
      .test('required', 'Futures month is required', (value, context) => {
        if (
          (context.parent.subkind === 'BASIS' ||
            context.parent.subkind === 'HTA' ||
            (context.parent.subkind === 'CASH_SALE' && basisMode)) &&
          !value?.trim()
        ) {
          return false
        }
        return true
      })
  })

  const shouldSaveAmount = (value) => {
    if (value?.subkind === 'CASH_SALE' && !basisMode) {
      return true
    }

    return false
  }

  const shouldSaveBasisAmount = (value) => {
    if (value?.subkind === 'CASH_SALE' && basisMode) {
      return true
    }

    if (value?.subkind === 'BASIS') {
      return true
    }

    return false
  }

  const shouldSaveFutureAmount = (value) => {
    if (value?.subkind === 'CASH_SALE' && basisMode) {
      return true
    }

    if (value?.subkind === 'HTA') {
      return true
    }

    return false
  }

  const shouldSaveFeeAmount = (value) => {
    if (value?.subkind !== 'NPE') {
      return true
    }

    return false
  }

  const shouldSaveFuturesMonth = (value) => {
    if (value?.subkind === 'CASH_SALE' && !basisMode) {
      return false
    }

    if (value?.subkind === 'NPE') {
      return false
    }

    return true
  }
  const handleSaleSubmit = async (value, { setFieldError }) => {
    console.log('payload ', value)
    try {
      if (type === 'CREATE_SALE') {
        toast.promise(
          createSale({
            variables: {
              input: {
                kind: 'SALE',
                subkind: value?.subkind,
                clientId: id,
                profileId: value?.profileId,
                buyerId: value?.buyerId,
                amount: shouldSaveAmount(value) ? parseFloat(value?.amount) : null,
                basisAmount: shouldSaveBasisAmount(value) ? parseFloat(value?.basisAmount) : null,
                futuresAmount: shouldSaveFutureAmount(value)
                  ? parseFloat(value?.futuresAmount)
                  : null,
                feeAmount: shouldSaveFeeAmount(value) ? parseFloat(value?.feeAmount) : null,
                feeMode: value?.feeMode,
                quantity: value?.quantity,
                deliveryStartDate: getDateString(value?.deliveryStartDate),
                deliveryEndDate: getDateString(value?.deliveryEndDate),
                orderExpiryDate: getDateString(value?.orderExpiryDate),
                futuresMonth: shouldSaveFuturesMonth(value) ? value?.futuresMonth : null,
                saleDate: getDateString(value?.saleDate),
                delivered: value?.delivered,
                referenceNumber: value?.referenceNumber,
                unloadNumber: value?.unloadNumber,
                notes: value?.notes,
                fob: value?.fob,
                soldTo: value?.soldTo,
                location: value?.location,
                visibleToClient: value?.visibleToClient,
                transportStatus: value?.transportStatus,
                transportToLocation: value?.transportToLocation
              }
            },
            onError: (error) => {
              console.log('api error ', error)

              const errors = error?.graphQLErrors[0]?.extensions?.response?.message || []
              for (let index = 0; index < errors.length; index++) {
                const e = errors[index]
                setFieldError(e.field, e.error)
              }

              throw error
            },
            onCompleted: closeModal,
            refetchQueries: [
              {
                query: GET_SALES_PROFILES,
                variables: {
                  clientId: id,
                  productionYearId: Number(productionYearId)
                }
              },
              {
                query: GET_PROFILE_SUMMARIES,
                variables: {
                  clientId: id,
                  productionYear: Number(productionYearId)
                }
              }
            ]
          }),
          {
            loading: 'Creating the sale...',
            success: 'Sale created successfully.',
            error: 'Something went wrong while creating the sale.'
          }
        )
      }
      if (type === 'CONVERT_STORAGE') {
        toast.promise(
          createSale({
            variables: {
              input: {
                kind: 'SALE',
                subkind: value?.subkind,
                clientId: id,
                profileId: value?.profileId,
                buyerId: value?.buyerId,
                amount: shouldSaveAmount(value) ? parseFloat(value?.amount) : null,
                basisAmount: shouldSaveBasisAmount(value) ? parseFloat(value?.basisAmount) : null,
                futuresAmount: shouldSaveFutureAmount(value)
                  ? parseFloat(value?.futuresAmount)
                  : null,
                feeAmount: shouldSaveFeeAmount(value) ? parseFloat(value?.feeAmount) : null,
                feeMode: value?.feeMode,
                quantity: value?.quantity,
                deliveryStartDate: getDateString(value?.deliveryStartDate),
                deliveryEndDate: getDateString(value?.deliveryEndDate),
                orderExpiryDate: getDateString(value?.orderExpiryDate),
                futuresMonth: shouldSaveFuturesMonth(value) ? value?.futuresMonth : null,
                saleDate: getDateString(value?.saleDate),
                delivered: value?.delivered,
                referenceNumber: value?.referenceNumber,
                unloadNumber: value?.unloadNumber,
                notes: value?.notes,
                fob: value?.fob,
                soldTo: value?.soldTo,
                location: value?.location,
                visibleToClient: value?.visibleToClient,
                transportStatus: value?.transportStatus,
                transportToLocation: value?.transportToLocation
              }
            },
            onCompleted,
            refetchQueries: [
              {
                query: GET_SALES_PROFILES,
                variables: {
                  clientId: id,
                  productionYearId: Number(productionYearId)
                }
              },
              {
                query: GET_PROFILE_SUMMARIES,
                variables: {
                  clientId: id,
                  productionYear: Number(productionYearId)
                }
              }
            ]
          }),
          {
            loading: 'Creating the sale...',
            success: 'Sale created successfully.',
            error: 'Something went wrong while creating the sale.'
          }
        )
      }
      if (type === 'EDIT_SALE') {
        toast.promise(
          updateSale({
            variables: {
              input: {
                id: salesIntialValues?.id,
                kind: 'SALE',
                subkind: value?.subkind,
                profileId: value?.profileId,
                buyerId: value?.buyerId,
                amount: shouldSaveAmount(value) ? parseFloat(value?.amount) : null,
                basisAmount: shouldSaveBasisAmount(value) ? parseFloat(value?.basisAmount) : null,
                futuresAmount: shouldSaveFutureAmount(value)
                  ? parseFloat(value?.futuresAmount)
                  : null,
                feeAmount: shouldSaveFeeAmount(value) ? parseFloat(value?.feeAmount) : null,
                feeMode: value?.feeMode,
                quantity: value?.quantity,
                deliveryStartDate: getDateString(value?.deliveryStartDate),
                deliveryEndDate: getDateString(value?.deliveryEndDate),
                orderExpiryDate: getDateString(value?.orderExpiryDate),
                futuresMonth: shouldSaveFuturesMonth(value) ? value?.futuresMonth : null,
                saleDate: getDateString(value?.saleDate),
                delivered: value?.delivered,
                referenceNumber: value?.referenceNumber,
                unloadNumber: value?.unloadNumber,
                notes: value?.notes,
                fob: value?.fob,
                soldTo: value?.soldTo,
                location: value?.location,
                visibleToClient: value?.visibleToClient,
                transportStatus: value?.transportStatus,
                transportToLocation: value?.transportToLocation
              }
            },
            onError: (error) => {
              console.log('api error ', error)

              const errors = error?.graphQLErrors[0]?.extensions?.response?.message || []
              for (let index = 0; index < errors.length; index++) {
                const e = errors[index]
                setFieldError(e.field, e.error)
              }

              throw error
            },
            onCompleted: closeModal,
            refetchQueries: [
              {
                query: GET_SALES_PROFILES,
                variables: {
                  clientId: id,
                  productionYearId: Number(productionYearId)
                }
              },
              {
                query: GET_PROFILE_SUMMARIES,
                variables: {
                  clientId: id,
                  productionYear: Number(productionYearId)
                }
              }
            ]
          }),
          {
            loading: 'Updating the sale...',
            success: 'Sale updated successfully.',
            error: 'Something went wrong while updating the sale.'
          }
        )
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }

  const handleDeliveryDelete = (id) => {
    toast.promise(
      deleteDelivery({
        variables: {
          id
        },
        refetchQueries: [GET_DELIVERIES]
      }),
      {
        loading: 'Deleting the delivery...',
        success: 'Delivery deleted successfully.',
        error: 'Error while deleting the Delivery.'
      }
    )
  }

  const handleDeliverySubmit = async (value) => {
    try {
      toast.promise(
        createDelivery({
          variables: {
            input: {
              saleId: currentSaleValues.saleId,
              amount: value?.deliveryAmount,
              date: value?.date,
              file: value?.file,
              notes: value?.notes
            }
          },
          refetchQueries: [GET_DELIVERIES, GET_SALES_PROFILES]
        }),
        {
          loading: 'Adding delivery...',
          success: 'Delivery added successfully.',
          error: 'Error while adding the Delivery.'
        }
      )
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }

  const handleSaleAdjustmentSubmit = async (value, resetForm) => {
    if (saleAdjustmentAction === 'CREATE') {
      toast.promise(
        createSaleAdjustment({
          variables: {
            input: {
              saleId: currentSaleValues.saleId,
              futuresMonth: value?.futuresMonth,
              basisAmount: value?.basis,
              totalProduction: value?.totalProduction
            }
          },
          onCompleted: () => resetForm(),
          refetchQueries: [GET_SALE_ADJUSTMENTS, GET_SALES_PROFILES]
        }),
        {
          loading: 'Adding sale adjustment...',
          success: 'Sale Adjustment added successfully.',
          error: 'Error while adding the Sale Adjustment.'
        }
      )
    } else {
      toast.promise(
        updateSaleAdjustment({
          variables: {
            input: {
              id: saleAdjustmentValues?.id,
              saleId: currentSaleValues.saleId,
              futuresMonth: value?.futuresMonth,
              basisAmount: value?.basis,
              totalProduction: value?.totalProduction
            }
          },
          onCompleted: () => {
            setSaleAdjustmentAction('CREATE')
            setSaleAdjustmentValues(saleAdjustmentInitialValues)
            resetForm()
          },
          refetchQueries: [GET_SALE_ADJUSTMENTS, GET_SALES_PROFILES]
        }),
        {
          loading: 'Updating the sale adjustment...',
          success: 'Sale Adjustment updated successfully.',
          error: 'Error while updating the Sale Adjustment.'
        }
      )
    }
  }

  const handleSaleAdjustmentDelete = (id) => {
    toast.promise(
      deleteSaleAdjustment({
        variables: {
          id
        },
        refetchQueries: [GET_SALE_ADJUSTMENTS]
      }),
      {
        loading: 'Deleting the adjustment...',
        success: 'Sale Adjustment deleted successfully.',
        error: 'Error while deleting the Sale Adjustment.'
      }
    )
  }

  const getAdjustmentValues = (type) => {
    if (type === 'PRICE') {
      const { subkind, amount, basisAmount, futuresAmount, feeAmount } = salesIntialValues
      if (subkind === 'CASH_SALE') {
        return amount ? amount - feeAmount : basisAmount + futuresAmount - feeAmount
      }
      if (subkind === 'BASIS') {
        return basisAmount + futuresAmount - feeAmount
      }

      return futuresAmount - feeAmount
    }

    if (type === 'QUANTITY') {
      const { drylandAcres, drylandYield, irrigatedAcres, irrigatedYield } = profile
      return drylandAcres * drylandYield + irrigatedAcres * irrigatedYield
    }
    return 0
  }

  const getTotalAdjustmentValues = (type) => {
    if (type === 'CASH') {
      const { subkind, amount, basisAmount, futuresAmount, feeAmount } = salesIntialValues
      let price = 0
      if (subkind === 'CASH_SALE') {
        price = amount ? amount - feeAmount : basisAmount + futuresAmount - feeAmount
      } else if (subkind === 'BASIS') {
        price = basisAmount + futuresAmount - feeAmount
      } else {
        price = futuresAmount - feeAmount
      }

      const cash = saleAdjustmentsData?.getSaleAdjustments?.map(
        (adjustment) => price + adjustment.basisAmount
      )
      return cash?.reduce((acc, value) => acc + value, 0)
    }

    return 0
  }

  const setFutureValue = (values, setFieldValue) => {
    if (values?.subkind === 'BASIS' && values?.futuresMonth) {
      const monthYear = values?.futuresMonth ? values?.futuresMonth.split(' ') : []
      const cropProfile = cropsProfileData.find((item) => item.id === values?.profileId)
      const salesData = pricingsData.getFuturePricings.find(
        (item) =>
          item.month === monthYear[0] &&
          item.year === monthYear[1] &&
          item.crop?.id === cropProfile.crop.id
      )
      setFieldValue('futuresAmount', salesData?.price ? salesData.price : 0)
    }
  }

  const modal = () => {
    if (activeTabIndex === 1) {
      return (
        <Formik
          initialValues={deliveryIntialValues}
          validationSchema={deliverySchema}
          onSubmit={handleDeliverySubmit}
        >
          <Form>
            <div className="bg-white px-4 py-2 shadow sm:rounded-lg sm:p-2">
              <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <h3 className="my-2 text-lg font-bold leading-6 text-gray-900">
                    Past deliveries
                  </h3>
                  {loading && (
                    <div className="mx-32 w-96">
                      <Loader message="Fetching deliveries..." variant="fetchData" />
                    </div>
                  )}
                  {error && (
                    <div className=" w-96">
                      <AlertBox message="Oops! Something went wrong." />
                    </div>
                  )}
                  {!loading &&
                    !error &&
                    (deliveriesData?.getDeliveries?.length > 0 ? (
                      <div className="mb-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 px-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Amount
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Notes
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {deliveriesData?.getDeliveries?.map((delivery) => (
                                  <tr key={delivery?.id}>
                                    <td className="relative whitespace-nowrap py-4 px-4 text-left text-sm sm:pr-8 sm:pl-6">
                                      {dayjs(delivery?.date).format('MMM DD YYYY')}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500">
                                      {delivery?.amount}
                                    </td>
                                    <td className="whitespace-nowrap px-6  py-2 text-sm text-gray-500">
                                      {delivery?.notes}
                                    </td>
                                    <td className="whitespace-nowrap px-6  py-2 text-sm text-gray-500">
                                      <div>
                                        <button
                                          type="button"
                                          className="flex-shrink-0 pr-4 text-emerald-200 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                          onClick={() => {
                                            handleDeliveryDelete(delivery.id)
                                          }}
                                        >
                                          <div className="text-emerald-600 hover:text-emerald-900">
                                            Delete
                                          </div>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-8 sm:pl-6">
                                    Total delivered
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm sm:pr-8 sm:pl-6">
                                    {deliveriesData?.getDeliveries.reduce(
                                      (acc, delivery) => acc + delivery.amount,
                                      0
                                    )}
                                    &nbsp; out of {salesIntialValues.quantity}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mx-32 w-96">
                        <EmptyState
                          className="w-96 p-36"
                          header="No deliveries found"
                          message="Please add a delivery."
                          disabled
                        />
                      </div>
                    ))}
                  <h3 className="text-lg font-bold leading-6 text-gray-900">Add new delivery</h3>
                  <div className="col-span-5 my-4  w-1/2">
                    <Input label="Amount" type="number" name="deliveryAmount" />
                  </div>
                  <div className="col-span-5 w-1/2 sm:col-span-3">
                    <CustomDateInput label="Delivery date" name="date" />
                  </div>
                  {/* <div className="col-span-5 my-4 w-1/2">
                    <Input label="Unload number" type="text" name="unloadNumber" />
                  </div> */}
                  <div className="my-4   sm:col-span-5">
                    <label htmlFor="about" className="block text-sm font-bold text-gray-700">
                      Delivery notes
                    </label>
                    <div className="my-4 ">
                      <Field
                        as="textarea"
                        rows={3}
                        type="notes"
                        id="notes"
                        name="notes"
                        defaultValue=""
                        className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">Any notes related to this delivery</p>
                  </div>
                  <Button type="submit" color="info" label="Add delivery" />
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <Button type="button" color="neutral" label="Close" onClick={() => closeModal()} />
            </div>
          </Form>
        </Formik>
      )
    }

    if (activeTabIndex === 2) {
      return (
        <Formik
          enableReinitialize
          initialValues={saleAdjustmentValues}
          validationSchema={saleAdjustmentSchema}
          onSubmit={(values, { resetForm }) => {
            handleSaleAdjustmentSubmit(values, resetForm)
          }}
        >
          <Form>
            <div className="bg-white px-4 py-2 shadow sm:rounded-lg sm:p-2">
              <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="mt-5 md:col-span-2 md:mt-0">
                  {loadingAdjustments && (
                    <div className="mx-32 w-96">
                      <Loader message="Fetching..." variant="fetchData" />
                    </div>
                  )}
                  {errorAdjustments && (
                    <div className=" w-96">
                      <AlertBox message="Oops! Something went wrong." />
                    </div>
                  )}
                  {!loadingAdjustments &&
                    !errorAdjustments &&
                    (saleAdjustmentsData.getSaleAdjustments?.length > 0 ? (
                      <div className="mb-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 px-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Futures Month
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Price
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Basis
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Cash
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    % of total Production
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Total
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {saleAdjustmentsData.getSaleAdjustments?.map((adjustment) => (
                                  <tr key={adjustment?.id}>
                                    <td className="relative whitespace-nowrap py-4 px-4 text-left text-sm sm:pr-8 sm:pl-6">
                                      {adjustment.futuresMonth}
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-500">
                                      ${getAdjustmentValues('PRICE')}
                                    </td>
                                    <td className="whitespace-nowrap px-4  py-2 text-sm text-gray-500">
                                      ${adjustment.basisAmount || 0}
                                    </td>
                                    <td className="whitespace-nowrap px-4  py-2 text-sm text-gray-500">
                                      ${getAdjustmentValues('PRICE') + adjustment.basisAmount}
                                    </td>
                                    <td className="whitespace-nowrap px-4  py-2 text-sm text-gray-500">
                                      {adjustment?.totalProduction || 0}%
                                    </td>
                                    <td className="whitespace-nowrap px-4  py-2 text-sm text-gray-500">
                                      {(
                                        (getAdjustmentValues('QUANTITY') *
                                          adjustment?.totalProduction) /
                                        100
                                      )?.toLocaleString('US')}
                                    </td>
                                    <td className="whitespace-nowrap px-4  py-2 text-sm text-gray-500">
                                      $
                                      {(
                                        (getAdjustmentValues('PRICE') + adjustment.basisAmount) *
                                        ((getAdjustmentValues('QUANTITY') *
                                          adjustment?.totalProduction) /
                                          100)
                                      )?.toLocaleString('US')}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                      <div>
                                        <button
                                          type="button"
                                          className="flex-shrink-0 p-1 text-emerald-200 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                          onClick={() => {
                                            setSaleAdjustmentAction('EDIT')
                                            setSaleAdjustmentValues({
                                              id: adjustment.id,
                                              futuresMonth: adjustment.futuresMonth,
                                              basis: adjustment?.basisAmount,
                                              totalProduction: adjustment?.totalProduction
                                            })
                                          }}
                                        >
                                          <span className="sr-only">Edit</span>
                                          <div className="text-emerald-600 hover:text-emerald-900">
                                            Edit
                                          </div>
                                        </button>
                                        <button
                                          type="button"
                                          className="flex-shrink-0 pl-2 text-emerald-200 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                          onClick={() => {
                                            handleSaleAdjustmentDelete(adjustment.id)
                                          }}
                                        >
                                          <div className="text-emerald-600 hover:text-emerald-900">
                                            Delete
                                          </div>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td className="relative whitespace-nowrap py-4 pr-4 text-left text-sm font-medium sm:pr-8 sm:pl-6">
                                    Totals
                                  </td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td className="relative whitespace-nowrap px-4 py-2 text-left text-sm">
                                    ${getTotalAdjustmentValues('CASH')}
                                  </td>
                                  <td className="relative whitespace-nowrap px-4 py-2 text-left text-sm">
                                    {saleAdjustmentsData?.getSaleAdjustments?.reduce(
                                      (acc, adjustment) => acc + adjustment.totalProduction,
                                      0
                                    )}
                                    %
                                  </td>
                                  <td className="relative whitespace-nowrap px-4 py-2 text-left text-sm">
                                    {(
                                      (getAdjustmentValues('QUANTITY') *
                                        saleAdjustmentsData?.getSaleAdjustments?.reduce(
                                          (acc, adjustment) => acc + adjustment.totalProduction,
                                          0
                                        )) /
                                      100
                                    )?.toLocaleString('US')}
                                  </td>
                                  <td className="relative whitespace-nowrap px-4 py-2 text-left text-sm">
                                    $
                                    {(
                                      getTotalAdjustmentValues('CASH') *
                                      ((getAdjustmentValues('QUANTITY') *
                                        saleAdjustmentsData?.getSaleAdjustments?.reduce(
                                          (acc, adjustment) => acc + adjustment.totalProduction,
                                          0
                                        )) /
                                        100)
                                    )?.toLocaleString('US')}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mx-32 w-96">
                        <EmptyState
                          className="w-96 p-36"
                          header="No items found"
                          message="Please add an item."
                          disabled
                        />
                      </div>
                    ))}
                  <h3 className="text-lg font-bold leading-6 text-gray-900">
                    {saleAdjustmentAction === 'CREATE' ? 'Add new item' : 'Edit item'}
                  </h3>
                  <div className="col-span-5 my-2 sm:col-span-3">
                    <label htmlFor="futuresMonth" className="block text-sm font-bold text-gray-700">
                      Futures month
                    </label>
                    <SelectInput name="futuresMonth" crop={crop} profile={profile} />
                  </div>
                  <div className="col-span-5 w-1/2 sm:col-span-3">
                    <SignedInput label="Basis" type="number" name="basis" unit="$" />
                  </div>
                  <div className="col-span-5  my-4 w-1/2 sm:col-span-3">
                    <Input label="% of total production" type="number" name="totalProduction" />
                  </div>

                  <div className="grid grid-cols-6 gap-4">
                    <Button
                      type="submit"
                      color="info"
                      label={saleAdjustmentAction === 'CREATE' ? 'Add item' : 'Edit item'}
                    />
                    {saleAdjustmentAction === 'EDIT' && (
                      <Button
                        color="info"
                        label="Clear"
                        onClick={() => {
                          setSaleAdjustmentValues(saleAdjustmentInitialValues)
                          setSaleAdjustmentAction('CREATE')
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end gap-x-2">
              <Button type="button" color="neutral" label="Close" onClick={() => closeModal()} />
            </div>
          </Form>
        </Formik>
      )
    }

    if (!profileSummaryData && profileSummaryLoading) {
      return (
        <div className="mx-32 w-96">
          <Loader message="Loading..." variant="fetchData" />
        </div>
      )
    }

    if (!profileSummaryData && !profileSummaryLoading)
      return <AlertBox message="An error occured." />

    return (
      <Formik
        enableReinitialize
        initialValues={salesIntialValues}
        validationSchema={orderSchema}
        onSubmit={handleSaleSubmit}
      >
        {({ values, setFieldValue }) => {
          const summary = profileSummaryData.getProfileSummaries?.find(
            (item) => item?.profileId === values?.profileId
          )

          return (
            <Form>
              <div className="md:col-span-2 md:mt-0">
                <div className="grid grid-cols-10 gap-x-2 gap-y-1">
                  <div className="col-span-10 flex justify-between">
                    <div className="mr-3 w-full">
                      <label htmlFor="kind" className="block text-sm font-bold text-gray-700">
                        Sale type
                      </label>
                      <Field
                        as="select"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        type="subkind"
                        id="subkind"
                        name="subkind"
                        onChange={(obj) => {
                          setFieldValue('subkind', obj.target.value)
                          setFutureValue({ ...values, subkind: obj.target.value }, setFieldValue)
                        }}
                      >
                        <option key="null" disabled value="">
                          Select Type
                        </option>
                        {Object.keys(saleTypes).map((key) => (
                          <option key={key} value={key}>
                            {saleTypes[key]}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="subkind"
                        component="p"
                        className="mt-1 text-sm font-medium text-red-600"
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="kind" className="mb-1 block text-sm font-bold text-gray-700">
                        Commodity
                      </label>
                      <Field
                        as="select"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        type="profileId"
                        id="profileId"
                        name="profileId"
                        value={values?.profileId}
                        onChange={(obj) => {
                          setFieldValue('profileId', obj.target.value)
                          const profile = cropsProfileData?.find((p) => p.id === obj.target.value)
                          setCrop(profile.crop)
                        }}
                      >
                        <option key="null" disabled value="">
                          Select Commodity
                        </option>
                        {cropsProfileData?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}&nbsp;({item.productionYear?.name})
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="profileId"
                        component="p"
                        className="mt-1 text-sm font-medium text-red-600"
                      />
                    </div>
                  </div>
                  <div className="col-span-10 flex justify-between">
                    <div className="mr-3 w-full">
                      <label htmlFor="kind" className="mb-1 block text-sm font-bold text-gray-700">
                        Buyer
                      </label>
                      <Field
                        as="select"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        type="buyerId"
                        id="buyerId"
                        name="buyerId"
                        value={values?.buyerId}
                      >
                        <option key="null" disabled value="">
                          Select Buyer
                        </option>
                        {buyers?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="buyerId"
                        component="p"
                        className="mt-1 text-sm font-medium text-red-600"
                      />
                      <div className="mx-4 my-1 flex justify-end text-[14px] font-bold text-emerald-600">
                        <button
                          type="button"
                          onClick={() => {
                            setIsBuyerModalOpen(true)
                          }}
                        >
                          Add a buyer
                        </button>
                      </div>
                    </div>
                    <div className="w-full">
                      <SignedInput
                        label="Quantity"
                        min="1"
                        name="quantity"
                        type="number"
                        unit={crop?.unit.toLowerCase()}
                      />
                      {(summary?.unsold?.quantity || 0) >= 0 ? (
                        <div className="mt-1 rounded-md bg-emerald-50 p-1 text-gray-700">
                          <div className="ml-2 text-emerald-500">
                            {values?.profileId
                              ? summary
                                ? `You have ${Math.max(
                                    summary?.unsold?.quantity || 0,
                                    0
                                  )} ${crop?.unit.toLowerCase()} unsold from ${
                                    profile?.productionYear?.name
                                  }.`
                                : 'No data available to calculate unsold quantity.'
                              : `Select a commodity to see unsold quantity`}
                          </div>
                        </div>
                      ) : (
                        <div className="mt-1 rounded-md bg-red-50 p-1 text-gray-700">
                          <div className="ml-2 text-red-500">
                            {`You have exceeded total production from ${
                              profile?.productionYear?.name
                            } by ${renderQuantity(summary.unsold.quantity * -1)}
                             ${crop?.unit.toLowerCase() || ''}.`}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {values?.subkind === 'CASH_SALE' && (
                    <div className="col-span-10 flex">
                      <div className="w-1/2">
                        {!basisMode ? (
                          <SignedInput
                            key="cash"
                            label="Cash"
                            type="number"
                            min="0"
                            name="amount"
                            unit="$"
                          />
                        ) : (
                          <SignedInput
                            key="futuresAmount"
                            label="Futures"
                            type="number"
                            name="futuresAmount"
                            unit="$"
                          />
                        )}
                      </div>
                      <div className="ml-4 mt-8 flex w-1/2 items-start text-[14px] font-bold text-emerald-600">
                        <button
                          type="button"
                          onClick={() => {
                            setBasisMode(!basisMode)
                          }}
                        >
                          {!basisMode ? 'Enter as basis and futures' : 'Enter as cash'}
                        </button>
                      </div>
                    </div>
                  )}

                  {values?.subkind === 'CASH_SALE' && basisMode && (
                    <div className="col-span-10 flex justify-between">
                      <div className="mr-3 mt-3 w-full sm:col-span-3">
                        <SignedInput label="Basis" type="number" name="basisAmount" unit="$" />
                      </div>
                      <div className="mt-3 w-full sm:col-span-3">
                        <label htmlFor="about" className="block text-sm font-bold text-gray-700">
                          Futures month
                        </label>
                        <SelectInput name="futuresMonth" crop={crop} profile={profile} />
                        {!values.futuresAmount && (
                          <div className="mt-1 rounded-md bg-emerald-50 p-1 text-gray-700">
                            <div className="ml-2 text-emerald-500">
                              Missing price for this futures month.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {values?.subkind === 'BASIS' && (
                    <div className="col-span-10 flex justify-between">
                      <div className="mr-3 mt-3 w-full sm:col-span-3">
                        <SignedInput label="Basis" type="number" name="basisAmount" unit="$" />
                      </div>
                      <div className="mt-3 w-full sm:col-span-3">
                        <label htmlFor="about" className="block text-sm font-bold text-gray-700">
                          Futures month
                        </label>
                        <SelectInput
                          name="futuresMonth"
                          crop={crop}
                          profile={profile}
                          onChange={(obj) => {
                            setFieldValue('futuresMonth', obj)
                            setFutureValue({ ...values, futuresMonth: obj }, setFieldValue)
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {values?.subkind === 'HTA' && (
                    <div className="col-span-10 flex justify-between">
                      <div className="mr-3 mt-3 w-full sm:col-span-3">
                        <SignedInput label="Futures" type="number" name="futuresAmount" unit="$" />
                      </div>
                      <div className="mt-3 w-full sm:col-span-3">
                        <label htmlFor="about" className="block text-sm font-bold text-gray-700">
                          Futures month
                        </label>
                        <SelectInput name="futuresMonth" crop={crop} profile={profile} />
                      </div>
                    </div>
                  )}

                  {values?.subkind !== 'NPE' && values?.subkind !== 'OPEN_STORAGE' && (
                    <div className="col-span-5 pr-1">
                      <div>
                        <SignedInput label="Fees" type="number" min="0" name="feeAmount" unit="$" />
                      </div>
                    </div>
                  )}

                  {values?.subkind !== 'NPE' && values?.subkind !== 'OPEN_STORAGE' && <br />}
                  <div className="col-span-10 flex justify-between">
                    <div className="mr-3 w-full ">
                      <CustomDateInput label="Delivery Start" name="deliveryStartDate" />
                    </div>
                    <div className="w-full ">
                      <CustomDateInput
                        deliveryStartDate={values?.deliveryStartDate}
                        label="Delivery End"
                        name="deliveryEndDate"
                      />
                    </div>
                  </div>

                  <div className="col-span-5">
                    <CustomDateInput label="Sale date" name="saleDate" />
                  </div>
                  <div className="col-span-10" />
                  <div className="col-span-5">
                    <Input label="Contract Completed" type="checkbox" name="delivered" />
                  </div>
                  {([roles.MANAGER, roles.SUPER_MANAGER].includes(auth.user.kind) ||
                    salesIntialValues?.visibleToClient) && (
                    <div className="col-span-10 grid grid-cols-10 gap-2 rounded-lg bg-gray-100 p-2">
                      <div className="col-span-5">
                        <Input
                          label="Sold to"
                          type="text"
                          name="soldTo"
                          disabled={auth.user.kind === roles.CLIENT}
                        />
                      </div>
                      <div className="col-span-5">
                        <Input
                          label="Location"
                          type="text"
                          name="location"
                          disabled={auth.user.kind === roles.CLIENT}
                        />
                      </div>
                      {auth.user.kind !== roles.CLIENT && (
                        <div className="col-span-5">
                          <Input label="Visible to client" type="checkbox" name="visibleToClient" />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="col-span-10 flex justify-between">
                    <div className="mr-3 w-full">
                      <label htmlFor="kind" className="mb-1 block text-sm font-bold text-gray-700">
                        FOB / Delivered
                      </label>
                      <Field
                        as="select"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        type="transportStatus"
                        id="transportStatus"
                        name="transportStatus"
                        onChange={(obj) => {
                          setFieldValue('transportStatus', obj.target.value)
                        }}
                      >
                        <option key="null" disabled value="">
                          Select FOB / Delivered
                        </option>
                        {Object.keys(transportStatuses).map((key) => (
                          <option key={key} value={key}>
                            {transportStatuses[key]}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="transportStatus"
                        component="p"
                        className="mt-1 text-sm font-medium text-red-600"
                      />
                    </div>
                    <div className="w-full">
                      <Input
                        label="Location"
                        type="text"
                        id="transportToLocation"
                        name="transportToLocation"
                      />
                    </div>
                  </div>
                  <div className="col-span-5">
                    <Input label="Contract number" type="text" name="referenceNumber" />
                  </div>
                  <div className="col-span-5">
                    <Input label="Unload number" type="text" name="unloadNumber" />
                  </div>
                  <div className="col-span-10" />
                  <div className="sm:col-span-5">
                    <label htmlFor="about" className="block text-sm font-bold text-gray-700">
                      Notes
                    </label>
                    <div className="mt-1">
                      <Field
                        as="textarea"
                        rows={3}
                        type="notes"
                        id="notes"
                        name="notes"
                        defaultValue=""
                        className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                    <p className="text-sm text-gray-500">Any notes related to this sale</p>
                  </div>
                </div>
              </div>

              <div className="mt-2 flex justify-end gap-x-2">
                <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                <input type="submit" style={{ display: 'none' }} />
                <CreateSaleFormSubmitBtn label={type === 'EDIT_SALE' ? ' Save' : 'Add Sale'} />
              </div>
              <BuyerModal
                onCompleted={(buyer) => {
                  buyer?.id && setFieldValue('buyerId', buyer.id)
                }}
                closeModal={() => setIsBuyerModalOpen(false)}
                initialValues={{}}
                clientId={id}
                isOpen={isBuyerModalOpen}
              />
            </Form>
          )
        }}
      </Formik>
    )
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="min-w-[80vw] space-y-2">
        <div className="bg-white px-4 py-2 sm:rounded-lg sm:p-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-bold leading-6 text-gray-900">
                {type === 'EDIT_SALE' ? 'Edit sale' : 'Add Sale'}
              </h3>
            </div>
            <div className="md:col-span-2">
              {type === 'EDIT_SALE' && (
                <ul className="flex">
                  {saleTabs.map((tab, idx) => (
                    <li key={tab.label}>
                      <button
                        type="button"
                        className={`text-md mb-5 flex justify-center border-b-2 pb-2 pl-1 pr-20 text-center font-medium ${
                          idx === activeTabIndex
                            ? 'border-green_medium text-green_medium'
                            : ' border-gray-200 hover:border-green_medium'
                        }`}
                        onClick={() => {
                          setActiveTabIndex(idx)
                        }}
                      >
                        {tab.label}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              {modal()}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

export default SaleModal
